/** @jsxImportSource @emotion/react */
import { memo, useState } from "react";
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiIcon,
  EuiPopover,
} from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { useMouseOver } from "hooks/useMouseOver";
import { ColumnHeaderActionRemoveColumn } from "./actions/ColumnHeaderActionRemoveColumn";
import { ColumnHeaderBaseName } from "./ColumnHeaderBaseName";
import { useDataTableContext } from "../store/DataTableProvider";
import { ModalDeleteColumn } from "../modals/ModelDeleteColumn";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { ColumnHeaderBaseJoinLink } from "./ColumnHeaderBaseJoinLink";

/**
 * Gets the spreadsheet column address for column a specified position.
 * @param order
 * @returns The spreadsheet column address
 */
export const getColumnAddress = (order: number) => {
  let address = "";

  // Convert order to a base-64 number where A is 0 and Z is 25. Every loop we
  // reduce order by a power of 26 and calculate the character corresponding to
  // that power. Every character is offset from char code 65, which represents A.
  while (order >= 0) {
    const remainder = order % 26;
    address = String.fromCharCode(65 + remainder) + address;
    order = Math.floor(order / 26) - 1;
  }

  return address;
};

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  address: css`
    color: grey;
    margin-left: 5px;
  `,
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
};

interface ColumnHeaderBaseProps {
  displayName: string;
  eGridHeader: HTMLElement;
  index: number;
  tableId: string;
  tableKind: "data" | "analysis";
  columnId: string;
  columnDefinition: DataTableColumnDefinition;
  isColumnDeletable: boolean;
  isPinned: boolean;
  helpText: string | null;
}

/**
 * Component that renders a header for all column types.
 *
 * All all other header components should render this header and specify props
 * for displaying custom content.
 */
const ColumnHeaderBase = ({
  displayName,
  eGridHeader,
  index,
  tableId,
  tableKind,
  columnId,
  columnDefinition,
  isColumnDeletable,
  isPinned,
  helpText,
}: ColumnHeaderBaseProps) => {
  const isAdvancedMode = useDataTableContext((s) => s.isAdvancedMode);
  const pinColumn = useDataTableContext((s) => s.pinColumn);
  const unpinColumn = useDataTableContext((s) => s.unpinColumn);
  const { isMouseOver } = useMouseOver(eGridHeader);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [visibleModal, setVisibleModal] = useState<"remove">();

  return (
    <div css={styles.root} title={helpText ?? undefined}>
      {visibleModal === "remove" && (
        <ModalDeleteColumn
          columnId={columnId}
          onClose={() => setVisibleModal(undefined)}
        />
      )}

      <ColumnHeaderBaseName
        tableId={tableId}
        columnId={columnId}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        name={displayName}
      />

      {isPinned && (
        <EuiIcon
          aria-label="Pinned"
          color="subdued"
          size="s"
          type="pinFilled"
        />
      )}

      {isAdvancedMode && (
        <span css={styles.address}>{getColumnAddress(index)}</span>
      )}

      {(isMouseOver || isPopoverOpen) && (
        <EuiPopover
          button={
            <EuiButtonIcon
              aria-label="Context menu"
              color="text"
              iconType={() => (
                <span className="ag-icon ag-icon-menu" unselectable="on" />
              )}
              size="xs"
              onClick={togglePopover}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          anchorPosition="downLeft"
          panelPaddingSize="none"
          ownFocus={false}
        >
          <div css={styles.contextMenu}>
            <EuiButtonEmpty
              onClick={closePopover}
              iconType="iInCircle"
              color="text"
              size="s"
              disabled
            >
              Details
            </EuiButtonEmpty>

            <EuiButtonEmpty
              onClick={() => {
                closePopover();
                setIsRenaming(true);
              }}
              iconType="pencil"
              color="text"
              size="s"
            >
              Rename column
            </EuiButtonEmpty>

            <EuiButtonEmpty
              onClick={() => {
                closePopover();
                if (isPinned) {
                  void unpinColumn({
                    tableId,
                    columnId,
                  });
                } else {
                  void pinColumn({
                    tableId,
                    columnId,
                  });
                }
              }}
              iconType="pin"
              color="text"
              size="s"
            >
              {isPinned ? "Unpin column" : "Pin column"}
            </EuiButtonEmpty>

            {tableKind === "data" && (
              <ColumnHeaderActionRemoveColumn
                onClick={() => {
                  setVisibleModal("remove");
                  closePopover();
                }}
                isDisabled={!isColumnDeletable}
              />
            )}
          </div>
        </EuiPopover>
      )}

      {columnDefinition.kind === "join" && (
        <ColumnHeaderBaseJoinLink columnDefinition={columnDefinition} />
      )}
    </div>
  );
};

export const ColumnHeaderBaseMemo = memo(ColumnHeaderBase);
