import { EuiFormRow, EuiFormRowProps } from "@inscopix/ideas-eui";

interface FieldDefaultFormulaProps {
  children: EuiFormRowProps["children"];
}

export const FieldDefaultFormula = ({ children }: FieldDefaultFormulaProps) => {
  return (
    <EuiFormRow
      fullWidth
      label="Default cell value"
      helpText="Cells in this column will have this value until manually edited."
    >
      {children}
    </EuiFormRow>
  );
};
