import { getEnvVar } from "ideas.env";

export const ideasFeatures = {
  organizationsPanel: false,
  partialRefetchLink: false,
  devOnly:
    getEnvVar("ENV") === "ideas-local" ||
    getEnvVar("ENV") === "ideas-development",
  genericDataTables: getEnvVar("GDT") === "1",
};
