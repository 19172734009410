import { EuiButtonIcon } from "@inscopix/ideas-eui";
import { useAnalysisTableLayoutContext } from "pages/project/analysis/AnalysisTableLayoutProvider";
import { CellStatuses } from "components/CellStatusEditor/CellStatusEditor.types";
import {
  ToolCellStatusParam,
  ToolParamsGridRowDatum,
} from "../ToolParamsGrid.types";

export type ButtonToolCellStatusParamProps = {
  initialCellStatuses?: CellStatuses;
  rowId: ToolParamsGridRowDatum["id"];
  toolParam: ToolCellStatusParam;
  isInvalidInitialCellStatuses: boolean;
};

export const ButtonToolCellStatusParam = ({
  initialCellStatuses,
  isInvalidInitialCellStatuses,
  toolParam,
  rowId,
}: ButtonToolCellStatusParamProps) => {
  const { openModal } = useAnalysisTableLayoutContext();

  return (
    <EuiButtonIcon
      aria-label="Open cell status editor"
      iconType="namespace"
      style={{ marginRight: 10 }}
      color="primary"
      onClick={() =>
        openModal({
          type: "toolCellStatusParam",
          props: {
            initialCellStatuses,
            toolParam,
            rowId,
            isInvalidInitialCellStatuses,
          },
        })
      }
    />
  );
};
