import {
  ToolParamsGridRowDatum,
  ToolCellStatusParam,
} from "../ToolParamsGrid.types";
import { useToolParamsGridContext } from "../ToolParamsGridProvider";
import { isToolPathParam } from "../ToolParamsGrid.helpers";
import { captureException } from "@sentry/react";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ModalToolCellStatusParamWrapper } from "./ModalToolCellStatusParamWrapper";
import { ModalToolCellStatusParamNoSourceFile } from "./ModalToolCellStatusParamNoSourceFile";
import { useToolParamsGridRowDataContext } from "../ToolParamsGridRowDataProvider";
import assert from "assert";
import { useProjectPermissionStore } from "pages/project/ProjectPermissionProvider";
import { CellStatuses } from "components/CellStatusEditor/CellStatusEditor.types";
import { ModalToolCellStatusParamBase } from "./ModalToolCellStatusParam";

export type ModalToolCellStatusParamProps = {
  initialCellStatuses?: CellStatuses;
  rowId: ToolParamsGridRowDatum["id"];
  toolParam: ToolCellStatusParam;
  isInvalidInitialCellStatuses: boolean;
  onClose: () => void;
};

/**
 * A component for editing any `ToolRoiFrameParam` grid cells
 */
export const ModalToolCellStatusParam = ({
  toolParam,
  initialCellStatuses,
  isInvalidInitialCellStatuses,
  onClose,
  rowId,
}: ModalToolCellStatusParamProps) => {
  const data = useToolParamsGridRowDataContext((s) =>
    s.rowData.find((row) => row.id === rowId),
  );
  assert(
    data !== undefined,
    "Expected row data to be defined in ModalToolCellStatusParam",
  );

  const hasEditPermission = useProjectPermissionStore(
    (s) => s.permissions["edit"],
  );
  const readOnly = data.task_id !== undefined || !hasEditPermission;

  const { toolSpec } = useToolParamsGridContext();
  const updateRowDatum = useToolParamsGridRowDataContext(
    (s) => s.updateRowDatum,
  );

  const cellSetFileParamKey = toolParam.type.source_files.find(
    (file) => file.data?.type === "cell_set",
  )?.key;

  const cellSetFileParam = toolSpec.params.find(
    (param) => param.key === cellSetFileParamKey,
  );

  const eventSetFileParamKey = toolParam.type.source_files.find(
    (file) => file.data?.type === "neural_events",
  )?.key;

  const eventSetFileParam = toolSpec.params.find(
    (param) => param.key === eventSetFileParamKey,
  );

  const qcReportFileParamKey = toolParam.type.source_files.find(
    (file) => file.data?.type === "calcium_image_qc_report",
  )?.key;

  const qcReportFileParam = toolSpec.params.find(
    (param) => param.key === qcReportFileParamKey,
  );

  // handle invalid linked param
  if (cellSetFileParam === undefined) {
    captureException(
      "Source file param key points to param that does not exist",
      {
        extra: {
          sourceFileParamKey: cellSetFileParamKey,
        },
      },
    );
    return (
      <ModalToolCellStatusParamWrapper onClose={onClose}>
        <CallOutError />
      </ModalToolCellStatusParamWrapper>
    );
  }

  if (!isToolPathParam(cellSetFileParam)) {
    captureException(
      "Unexpected param type for ToolRoiFrameParam source file",
      {
        extra: {
          sourceFileParam: cellSetFileParam,
        },
      },
    );
    return (
      <ModalToolCellStatusParamWrapper onClose={onClose}>
        <CallOutError />
      </ModalToolCellStatusParamWrapper>
    );
  }

  const onAccept = (statuses: CellStatuses) => {
    const newParamValue = JSON.stringify(statuses);
    updateRowDatum(data.id, {
      params: {
        [toolParam.key]: newParamValue,
      },
    });
    onClose();
  };

  const NoSourceFiles = () => (
    <ModalToolCellStatusParamNoSourceFile
      rowDatum={data}
      sourceFileParam={cellSetFileParam}
      onClose={onClose}
      readOnly={readOnly}
    />
  );

  return (
    <ModalToolCellStatusParamBase
      cellSetFileIds={(data.params[cellSetFileParam.key] ?? []) as string[]}
      eventSetFileIds={
        eventSetFileParam !== undefined
          ? ((data.params[eventSetFileParam.key] ?? []) as string[])
          : []
      }
      qcReportFIleIds={
        qcReportFileParam !== undefined
          ? ((data.params[qcReportFileParam.key] ?? []) as string[])
          : []
      }
      initialCellStatuses={initialCellStatuses}
      isReadOnly={false}
      onSave={onAccept}
      onClose={onClose}
      NoSourceFiles={NoSourceFiles}
      isInvalidInitialCellStatuses={isInvalidInitialCellStatuses}
    />
  );
};
