import { EuiFieldText, EuiFormRow } from "@inscopix/ideas-eui";
import { forwardRef } from "react";

interface FieldColumnNameProps {
  autoFocus?: boolean;
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (field: { value: string; isValid: boolean }) => void;
}

export const FieldColumnName = forwardRef<
  HTMLInputElement,
  FieldColumnNameProps
>(function FieldColumnName(
  {
    autoFocus,
    label = "Name",
    placeholder = "Untitled column",
    value,
    onChange,
  },
  ref,
) {
  return (
    <EuiFormRow fullWidth label={label}>
      <EuiFieldText
        inputRef={ref}
        fullWidth
        aria-label="Column name"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const newName = e.target.value;
          onChange({
            value: newName,
            isValid: newName !== "",
          });
        }}
        icon="copyClipboard"
        maxLength={255}
        isInvalid={false}
        autoFocus={autoFocus}
      />
    </EuiFormRow>
  );
});
