/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";
import { PanelCreateColumnBoolean } from "./panels/PanelCreateColumnBoolean";
import { PanelCreateColumnFile } from "./panels/PanelCreateColumnFile";
import { PanelCreateColumnText } from "./panels/PanelCreateColumnText";
import { PanelCreateColumnFloat } from "./panels/PanelCreateColumnFloat";
import { PanelCreateColumnInteger } from "./panels/PanelCreateColumnInteger";
import { PanelCreateColumnFileMetadatum } from "./panels/PanelCreateColumnFileMetadatum";
import { useCallback, useState } from "react";
import { FieldColumnType } from "../fields/FieldColumnType";
import { css } from "@emotion/react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import assert from "assert";
import { ModalJoinDataTable } from "./ModalJoinDataTable";

export const COLUMN_KIND_MAP = {
  file: {
    name: "File",
    icon: "tokenFile",
    editable: true,
  },
  metadatum: {
    name: "File Metadatum",
    icon: "tokenObject",
    editable: false,
  },
  text: {
    name: "Text",
    icon: "tokenText",
    editable: true,
  },
  choice: {
    name: "Choice",
    icon: "tokenFlattened",
    editable: true,
  },
  boolean: {
    name: "Boolean",
    icon: "tokenBoolean",
    editable: true,
  },
  float: {
    name: "Float",
    icon: "tokenNumber",
    editable: true,
  },
  integer: {
    name: "Integer",
    icon: "tokenNumber",
    editable: true,
  },
  identifier: {
    name: "Identifier",
    icon: "tokenAnnotation",
    editable: true,
  },
  join: {
    name: "Join",
    icon: "tokenJoin",
    editable: false,
  },
  roi_frame: {
    name: "ROI Frame",
    icon: "tokenShape",
    editable: true,
  },
  cell_status: {
    name: "Cell Status",
    icon: "heatmap",
    editable: true,
  },
} as const satisfies Record<
  DataTableColumnDefinition["kind"],
  { name: string; icon: string; editable: boolean }
>;

export type ColumnType =
  | "file"
  | "metadatum"
  | "text"
  | "boolean"
  | "float"
  | "integer"
  | "join_data_table";

type Fields = {
  name: {
    value: string;
    isValid: boolean;
    isDirty: boolean;
  };
  default_formula: {
    value: string | undefined;
    isValid: boolean;
  };
  definition: {
    value: DataTableColumnDefinition | undefined;
    isValid: boolean;
  };
};

const INITIAL_FIELDS: Fields = {
  name: {
    value: "",
    isValid: false,
    isDirty: false,
  },
  default_formula: {
    value: undefined,
    isValid: true,
  },
  definition: {
    value: undefined,
    isValid: false,
  },
};

export type PanelProps = {
  fields: Fields;
  onFieldChange: <K extends keyof Fields>(
    fieldName: K,
    field: Fields[K],
  ) => void;
};

interface ModalCreateColumnProps {
  onClose: () => void;
}

/** Component that renders a modal for creating a new column */
export const ModalCreateColumn = ({ onClose }: ModalCreateColumnProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const createColumn = useDataTableContext((s) => s.createColumn);
  const [isLoading, setIsLoading] = useState(false);
  const [columnType, setColumnType] = useState<ColumnType>();
  const [formId] = useState(htmlIdGenerator()());
  const [fields, setFields] = useState(INITIAL_FIELDS);

  const handleFieldChange = useCallback(
    <T extends keyof Fields>(fieldName: T, field: Fields[T]) => {
      setFields((prevFields) => ({
        ...prevFields,
        [fieldName]: field,
      }));
    },
    [],
  );

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const columnType = fields.definition.value?.kind;
      assert(columnType !== undefined);
      assert(selectedTableId !== undefined);
      const { error } = await createColumn({
        tableId: selectedTableId,
        name: fields.name.value,
        defaultFormula: fields.default_formula.value,
        definition: fields.definition.value as DataTableColumnDefinition,
        editable: COLUMN_KIND_MAP[columnType].editable,
      });
      assert(error === undefined);
      addUtilityToastSuccess("Column created");
    } catch (error) {
      addUtilityToastFailure("Failed to create column");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  if (columnType === "join_data_table") {
    return (
      <ModalJoinDataTable
        onBack={() => setColumnType(undefined)}
        onClose={onClose}
      />
    );
  }

  return (
    <EuiModal
      onClose={onClose}
      css={css`
        width: 600px;
      `}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">
          Insert Column(s)
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiForm
        id={formId}
        component="form"
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          void handleSubmit();
        }}
      >
        <EuiModalBody>
          <FieldColumnType
            autoFocus
            value={columnType}
            onChange={(columnType) => {
              setColumnType(columnType);
              if (columnType === undefined) {
                setFields(INITIAL_FIELDS);
              }
            }}
          />

          {(() => {
            switch (columnType) {
              case "boolean":
                return (
                  <PanelCreateColumnBoolean
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case "file":
                return (
                  <PanelCreateColumnFile
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case "metadatum":
                return (
                  <PanelCreateColumnFileMetadatum
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case "float":
                return (
                  <PanelCreateColumnFloat
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case "integer":
                return (
                  <PanelCreateColumnInteger
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case "text":
                return (
                  <PanelCreateColumnText
                    fields={fields}
                    onFieldChange={handleFieldChange}
                  />
                );
              case undefined:
                return null;
            }
          })()}
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButton
            type="submit"
            form={formId}
            isLoading={isLoading}
            fill
            disabled={Object.values(fields).some((field) => !field.isValid)}
          >
            Insert
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};
