import { CellStatuses } from "@inscopix/ideas-hyperformula";

interface CellValueRendererCellStatusesProps {
  cellStatuses: CellStatuses;
}

/** Component that renders an `CellStatuses` cell value` */
export const CellValueRendererCellStatuses = ({
  cellStatuses,
}: CellValueRendererCellStatusesProps) => {
  return <span>[{cellStatuses.statuses.join(", ")}]</span>;
};
