import {
  Ast,
  AstNodeType,
  CellStatuses,
  CellValue,
  DetailedCellError,
  Group,
  HyperFormula,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
  SimpleCellAddress,
} from "@inscopix/ideas-hyperformula";
import { immer } from "zustand/middleware/immer";
import { DataTableStoreState } from "./DataTableProvider.types";
import assert from "assert";
import { isDefined } from "utils/isDefined";
import { set } from "lodash";
import { ShapeJson } from "types/ToolRoiFrameParamValue/ToolRoiFrameParamValue";
import { CellStatus } from "components/CellStatusEditor/CellStatusEditor.types";

/** Represents the `get` function used to read a Zustand store */
type GetStoreState = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[1];

/** Represents the `set` function used to modify a Zustand store */
type SetStoreState = Parameters<
  Parameters<typeof immer<DataTableStoreState, [], []>>[0]
>[0];

/**
 * Traverses a HyperFormula abstract syntax tree (AST), calling a specified
 * function on every node in the tree.
 * @param ast AST to traverse.
 * @param callback Function called on every node in the AST.
 */
export const traverseAst = (ast: Ast, callback: (node: Ast) => void) => {
  // Apply callback on the root node
  callback(ast);

  // Apply callback on every child node
  switch (ast.type) {
    // Leaf nodes with no child nodes
    case AstNodeType.CELL_RANGE:
    case AstNodeType.CELL_REFERENCE:
    case AstNodeType.COLUMN_RANGE:
    case AstNodeType.EMPTY:
    case AstNodeType.ERROR:
    case AstNodeType.ERROR_WITH_RAW_INPUT:
    case AstNodeType.NAMED_EXPRESSION:
    case AstNodeType.NUMBER:
    case AstNodeType.ROW_RANGE:
    case AstNodeType.STRING:
      break;

    // Arrays with child nodes stored in "args" property
    case AstNodeType.ARRAY:
      ast.args.forEach((arg) => {
        arg.forEach((node) => {
          traverseAst(node, callback);
        });
      });
      break;

    // Binary operations with child nodes stored in "left" and "right"
    // properties
    case AstNodeType.CONCATENATE_OP:
    case AstNodeType.DIV_OP:
    case AstNodeType.EQUALS_OP:
    case AstNodeType.GREATER_THAN_OP:
    case AstNodeType.GREATER_THAN_OR_EQUAL_OP:
    case AstNodeType.LESS_THAN_OP:
    case AstNodeType.LESS_THAN_OR_EQUAL_OP:
    case AstNodeType.MINUS_OP:
    case AstNodeType.NOT_EQUAL_OP:
    case AstNodeType.PLUS_OP:
    case AstNodeType.POWER_OP:
    case AstNodeType.TIMES_OP:
      traverseAst(ast.left, callback);
      traverseAst(ast.right, callback);
      break;

    // Function calls with child nodes stored in "args" property
    case AstNodeType.FUNCTION_CALL:
      ast.args.forEach((arg) => {
        traverseAst(arg, callback);
      });
      break;

    // Operations with child node stored in "value" property
    case AstNodeType.MINUS_UNARY_OP:
    case AstNodeType.PERCENT_OP:
    case AstNodeType.PLUS_UNARY_OP:
      traverseAst(ast.value, callback);
      break;

    // Operations with child node stored in "expression" property
    case AstNodeType.PARENTHESIS:
      traverseAst(ast.expression, callback);
      break;
  }
};

/**
 * Represents a column default formula or custom cell formula that was changed
 * as the result of a table operation
 */
export class SideEffects {
  #engine: HyperFormula;
  #getStoreState: GetStoreState;
  #setStoreState: SetStoreState;

  #map = {
    columns: {} as {
      [tableId: string]: {
        [columnId: string]: {
          tableType: "data" | "analysis";
          defaultFormula: string;
        };
      };
    },
    cells: {} as {
      [tableId: string]: {
        [columnId: string]: {
          [rowId: string]: {
            tableType: "data" | "analysis";
            formula: string;
          };
        };
      };
    },
  };

  constructor(
    engine: HyperFormula,
    getStoreState: GetStoreState,
    setStoreState: SetStoreState,
  ) {
    this.#engine = engine;
    this.#getStoreState = getStoreState;
    this.#setStoreState = setStoreState;
  }

  /**
   * Generates an initial set of side-effects from every cell whose formula
   * stored in the HyperFormula engine does not match its formula stored in the
   * Zustand store.
   */
  init() {
    Object.entries(this.#engine.getAllSheetsFormulas()).forEach(
      ([tableKey, rows]) => {
        rows.forEach((row, rowIdx) => {
          row.forEach((newFormula, colIdx) => {
            if (isDefined(newFormula)) {
              const table = this.#getStoreState().tables.find(
                ({ key }) => key === tableKey,
              );
              assert(isDefined(table));
              const column = table.columns[colIdx];
              const row = table.rows[rowIdx];
              const cell = row.cells[colIdx];

              if (cell.formula !== newFormula) {
                if (cell.isCustomFormula) {
                  set(this.#map.cells, [table.id, column.id, row.id], {
                    tableType: table.kind,
                    formula: newFormula,
                  });
                } else {
                  set(this.#map.columns, [table.id, column.id], {
                    tableType: table.kind,
                    defaultFormula: newFormula,
                  });
                }
              }
            }
          });
        });
      },
    );
  }

  /**
   * Added a `FILE(Text)` formula for each file to a cell formula
   *
   * The updated formulas are stored as side-effects.
   * @param fileReferenceIds
   * @params address
   * @returns The updated formula.
   */
  addFiles = (fileReferenceIds: string[], address: SimpleCellAddress) => {
    // Read current formula
    const formula = this.#engine.getCellFormula(address);

    // If the cell does not contain a formula, overwrite it
    if (formula === undefined) {
      const fileFormulas = fileReferenceIds.map((id) => `FILE("${id}")`);
      if (fileFormulas.length === 0) {
        return "";
      } else if (fileFormulas.length === 1) {
        return `=${fileFormulas[0]}`;
      } else {
        return `=GROUP(${fileFormulas.join(",")})`;
      }
    }

    // Parse the formula as an AST
    const { ast } = this.#engine.formulaToAst(formula, address.sheet);

    // Check whether the current formula is an IdeasFile constructor
    const isFileFormula =
      ast.type === AstNodeType.FUNCTION_CALL && ast.procedureName === "FILE";

    // If the current formula is a IdeasFile constructor, create group with the
    // old and new file
    if (isFileFormula) {
      return this.#engine.astToFormula({
        type: AstNodeType.FUNCTION_CALL,
        procedureName: "GROUP",
        args: [
          ast,
          ...fileReferenceIds.map(
            (id) =>
              ({
                type: AstNodeType.FUNCTION_CALL,
                procedureName: "FILE",
                args: [
                  {
                    type: AstNodeType.STRING,
                    value: id,
                  },
                ],
              }) satisfies Ast,
          ),
        ],
      });
    }

    // Check whether the current formula is a Group constructor
    const isGroupFormula =
      ast.type === AstNodeType.FUNCTION_CALL && ast.procedureName === "GROUP";

    // If the current formula is a Group constructor, add the file to the group
    if (isGroupFormula) {
      fileReferenceIds.forEach((id) => {
        ast.args.push({
          type: AstNodeType.FUNCTION_CALL,
          procedureName: "FILE",
          args: [
            {
              type: AstNodeType.STRING,
              value: id,
            },
          ],
        });
      });
      return this.#engine.astToFormula(ast);
    }

    // If the current formula is neither an IdeasFile nor a Group constructor,
    // overwrite it as an IdeasFile constructor
    const fileFormulas = fileReferenceIds.map((id) => `FILE("${id}")`);
    if (fileFormulas.length === 0) {
      return "";
    } else if (fileFormulas.length === 1) {
      return `=${fileFormulas[0]}`;
    } else {
      return `=GROUP(${fileFormulas.join(",")})`;
    }
  };

  /**
   * Removes every `=FILE(Text)` referencing a specified file.
   *
   * The updated formulas are stored as side-effects.
   * @param fileReferenceId
   * @returns An array of every cell whose formula changed as a result of this
   * operation.
   */
  deleteFile = (fileReferenceId: string) => {
    const updatedCells: {
      address: SimpleCellAddress;
      newFormula: string;
    }[] = [];

    /**
     * Resets a formula if it is a file constructor.
     * @param formula
     * @param sheetId
     * @returns The updated formula.
     */
    const updateFormula = (formula: string, sheetId: number) => {
      const { ast } = this.#engine.formulaToAst(formula, sheetId);

      const isFileFormula =
        ast.type === AstNodeType.FUNCTION_CALL &&
        ast.procedureName === "FILE" &&
        ast.args[0].type === AstNodeType.STRING &&
        ast.args[0].value === fileReferenceId;

      const isGroupFormula =
        ast.type === AstNodeType.FUNCTION_CALL &&
        ast.procedureName === "GROUP" &&
        ast.args.some((arg) => {
          return (
            arg.type === AstNodeType.FUNCTION_CALL &&
            arg.procedureName === "FILE" &&
            arg.args[0].type === AstNodeType.STRING &&
            arg.args[0].value === fileReferenceId
          );
        });

      if (isFileFormula) {
        return "";
      } else if (isGroupFormula) {
        ast.args = ast.args.filter((arg) => {
          return !(
            arg.type === AstNodeType.FUNCTION_CALL &&
            arg.procedureName === "FILE" &&
            arg.args[0].type === AstNodeType.STRING &&
            arg.args[0].value === fileReferenceId
          );
        });
        return ast.args.length > 0 ? this.#engine.astToFormula(ast) : "";
      } else {
        return formula;
      }
    };

    Object.entries(this.#engine.getAllSheetsFormulas()).forEach(
      ([sheetName, rows]) => {
        const sheetId = this.#engine.getSheetId(sheetName);
        assert(isDefined(sheetId));
        const table = this.#getStoreState().tables.find(
          ({ key }) => key === sheetName,
        );
        assert(isDefined(table));

        rows.forEach((row, rowIdx) => {
          row.forEach((formula, colIdx) => {
            if (isDefined(formula)) {
              const newFormula = updateFormula(formula, sheetId);
              const column = table.columns[colIdx];
              const row = table.rows[rowIdx];
              const cell = row.cells[colIdx];

              // Formulas should not be updated for executed analysis table rows
              const shouldUpdateFormula =
                table.kind === "data" ||
                (table.kind === "analysis" && row.editable);

              if (newFormula !== formula && shouldUpdateFormula) {
                updatedCells.push({
                  address: { sheet: sheetId, col: colIdx, row: rowIdx },
                  newFormula: newFormula,
                });

                if (cell.isCustomFormula) {
                  set(this.#map.cells, [table.id, column.id, row.id], {
                    tableType: table.kind,
                    formula: newFormula,
                  });
                } else {
                  set(this.#map.columns, [table.id, column.id], {
                    tableType: table.kind,
                    defaultFormula: newFormula,
                  });
                }
              }
            }
          });
        });
      },
    );

    return updatedCells;
  };

  /**
   * Replaces the table key in every `=ROW_IDENTIFIER(Text, Number)` formula
   * with a new key.
   *
   * The updated formulas are stored as side-effects.
   * @param prevTableKey
   * @param newTableKey
   * @returns An array of every cell whose formula changed as a result of this
   * operation.
   */
  updateRowIdentifiers(prevTableKey: string, newTableKey: string) {
    const updatedCells: {
      address: SimpleCellAddress;
      newFormula: string;
    }[] = [];

    /**
     * Replaces the table key in a formula if present.
     * @param formula
     * @param sheetId
     * @returns The updated formula.
     */
    const updateFormula = (formula: string, sheetId: number) => {
      const { ast } = this.#engine.formulaToAst(formula, sheetId);

      // Update all row identifiers to use new table key
      traverseAst(ast, (node) => {
        if (
          node.type === AstNodeType.FUNCTION_CALL &&
          node.procedureName === "ROW_IDENTIFIER" &&
          node.args[0].type === AstNodeType.STRING &&
          node.args[0].value === prevTableKey
        ) {
          node.args[0].value = newTableKey;
        }
      });

      return this.#engine.astToFormula(ast);
    };

    Object.entries(this.#engine.getAllSheetsFormulas()).forEach(
      ([sheetName, rows]) => {
        const sheetId = this.#engine.getSheetId(sheetName);
        assert(isDefined(sheetId));
        const table = this.#getStoreState().tables.find(
          ({ key }) => key === sheetName,
        );
        assert(isDefined(table));

        rows.forEach((row, rowIdx) => {
          row.forEach((formula, colIdx) => {
            if (isDefined(formula)) {
              const newFormula = updateFormula(formula, sheetId);
              const column = table.columns[colIdx];
              const row = table.rows[rowIdx];
              const cell = row.cells[colIdx];

              if (newFormula !== formula) {
                updatedCells.push({
                  address: { sheet: sheetId, col: colIdx, row: rowIdx },
                  newFormula,
                });

                if (cell.isCustomFormula) {
                  set(this.#map.cells, [table.id, column.id, row.id], {
                    tableType: table.kind,
                    formula: newFormula,
                  });
                } else {
                  set(this.#map.columns, [table.id, column.id], {
                    tableType: table.kind,
                    defaultFormula: newFormula,
                  });
                }
              }
            }
          });
        });
      },
    );

    return updatedCells;
  }

  /**
   * Converts all formulas in a specified row to static formulas. The updated
   * formulas are stored as side-effects.
   *
   * Static formulas do not contain cell references and thus will always
   * evaluate to the same cell values regardless of other cell values in the
   * table.
   * @param tableId
   * @param rowId
   * @returns
   */
  convertToStaticFormulas(tableId: string, rowId: string) {
    const updatedCells: {
      address: SimpleCellAddress;
      newFormula: string;
    }[] = [];

    // Parse row
    const table = this.#getStoreState().tables.find(({ id }) => id === tableId);
    assert(isDefined(table));
    const sheetId = this.#engine.getSheetId(table.key);
    assert(isDefined(sheetId));
    const rowIdx = table.rows.findIndex(({ id }) => id === rowId);
    const row = table.rows[rowIdx];

    // Convert cell values to static formulas
    row.cells.forEach((cell, colIdx) => {
      const column = table.columns[colIdx];
      if (column.editable) {
        const staticFormula = convertCellValueToStaticFormula(cell.value);
        if (cell.formula !== staticFormula) {
          set(this.#map.cells, [table.id, column.id, row.id], {
            tableType: table.kind,
            formula: staticFormula,
          });

          updatedCells.push({
            address: { sheet: sheetId, col: colIdx, row: rowIdx },
            newFormula: staticFormula,
          });
        }
      }
    });

    return updatedCells;
  }

  /**
   * Updates the formulas in the Zustand store from currently calculated
   * side-effects.
   */
  applyOnStoreState() {
    this.#setStoreState((state) => {
      // Update column default formulas
      Object.entries(this.#map.columns).forEach(([tableId, i]) => {
        const table = state.tables.find(({ id }) => id === tableId);
        assert(isDefined(table));
        Object.entries(i).forEach(([columnId, { defaultFormula }]) => {
          const colIdx = table.columns.findIndex(({ id }) => id === columnId);
          const column = table.columns[colIdx];
          assert(isDefined(column));
          column.default_formula = defaultFormula;

          table.rows.forEach((row) => {
            if (!row.cells[colIdx].isCustomFormula) {
              row.cells[colIdx].formula = defaultFormula;
            }
          });
        });
      });

      // Update custom cell formulas
      Object.entries(this.#map.cells).forEach(([tableId, i]) => {
        const table = state.tables.find(({ id }) => id === tableId);
        assert(isDefined(table));
        Object.entries(i).forEach(([columnId, j]) => {
          const colIdx = table.columns.findIndex(({ id }) => id === columnId);
          Object.entries(j).forEach(([rowId, { formula }]) => {
            const row = table.rows.find(({ id }) => id === rowId);
            assert(isDefined(row));
            row.cells[colIdx].formula = formula;
          });
        });
      });
    });
  }

  /**
   * Formats the side-effects for sending in network requests.
   * @returns The serialized side-effects.
   */
  serialize() {
    const serializedSideEffects: SerializedSideEffects = {
      data_table_columns: [],
      analysis_table_columns: [],
      data_table_cells: [],
      analysis_table_cells: [],
    };

    Object.entries(this.#map.columns).forEach(([tableId, i]) => {
      Object.entries(i).forEach(([columnId, { tableType, defaultFormula }]) => {
        if (tableType === "analysis") {
          serializedSideEffects.analysis_table_columns.push({
            id: columnId,
            default_formula: defaultFormula,
          });
        } else {
          serializedSideEffects.data_table_columns.push({
            id: columnId,
            default_formula: defaultFormula,
          });
        }
      });
    });

    Object.entries(this.#map.cells).forEach(([tableId, i]) => {
      Object.entries(i).forEach(([columnId, j]) => {
        Object.entries(j).forEach(([rowId, { tableType, formula }]) => {
          if (tableType === "analysis") {
            serializedSideEffects.analysis_table_cells.push({
              table: tableId,
              column: columnId,
              row: rowId,
              formula,
            });
          } else {
            serializedSideEffects.data_table_cells.push({
              table: tableId,
              column: columnId,
              row: rowId,
              formula,
            });
          }
        });
      });
    });

    return serializedSideEffects;
  }
}

/** Represents operation side-effects formatted for network requests */
export type SerializedSideEffects = {
  data_table_columns: {
    id: string;
    default_formula: string;
  }[];

  analysis_table_columns: {
    id: string;
    default_formula: string;
  }[];

  data_table_cells: {
    table: string;
    column: string;
    row: string;
    formula: string;
  }[];

  analysis_table_cells: {
    table: string;
    column: string;
    row: string;
    formula: string;
  }[];
};

/**
 * Forces HyperFormula to recalculate every cell that passes a specified
 * callback condition.
 * @param engine The HyperFormula engine instance.
 * @param shouldRecalculate Callback that takes a cell value and returns a
 * boolean indicating whether the cell should be recalculated.
 */
export const recalculateCellValues = (
  engine: HyperFormula,
  shouldRecalculate: (
    address: SimpleCellAddress,
    formula: string | undefined,
    value: CellValue,
  ) => boolean,
) => {
  Object.entries(engine.getAllSheetsValues()).forEach(([sheetName, rows]) => {
    const sheetId = engine.getSheetId(sheetName);
    assert(isDefined(sheetId));
    rows.forEach((row, rowIdx) => {
      row.forEach((cellValue, colIdx) => {
        const address = { sheet: sheetId, col: colIdx, row: rowIdx };
        const formula = engine.getCellFormula(address);
        if (shouldRecalculate(address, formula, cellValue)) {
          engine.setCellContents(address, formula);
        }
      });
    });
  });
};

/**
 * Represents a valid analysis parameter value usable by the Task Execution
 * Service
 */
export type AnalysisParamValue =
  | number
  | string
  | boolean
  | null
  | ShapeJson[]
  | CellStatus[];

/**
 * Converts a HyperFormula cell value into a static formula string.
 *
 * Static formulas do not contain cell references and thus will always evaluate
 * to the same cell values regardless of other cell values in the table.
 * @param value
 * @returns The static.
 */
export const convertCellValueToStaticFormula = (value: CellValue): string => {
  // Ignore values that cannot be converted
  if (value === null || value instanceof DetailedCellError) {
    return "";
  }

  // No conversion needed for strings
  if (typeof value === "string") {
    return value;
  }

  // Convert numbers to strings
  if (typeof value === "number") {
    return value.toString();
  }

  // Convert boolean values to boolean constructor formulas
  if (typeof value === "boolean") {
    return value ? "=TRUE()" : "=FALSE()";
  }

  // Convert file values to file constructor formulas
  if (value instanceof IdeasFile) {
    return `=FILE("${value.attrs.referenceId}")`;
  }

  // Convert row identifier values to row identifier constructor formulas
  if (value instanceof RowIdentifier) {
    return `=ROW_IDENTIFIER("${value.tableKey}", ${value.rowIndex})`;
  }

  // Convert ROI frame values to ROI frame constructor formulas
  if (value instanceof RoiFrame) {
    const serialized = JSON.stringify(value.shapes).replace(/"/g, '\\"');
    return `=ROI_FRAME("${serialized}")`;
  }

  // Convert cell status values to cell statuses constructor formulas
  if (value instanceof CellStatuses) {
    const serialized = value.statuses.join(",");
    return `=CELL_STATUSES(${serialized})`;
  }

  // Convert group values recursively to group constructor formulas
  if (value instanceof Group) {
    const nestedFormulas = value.cellValues.map((cellValue) => {
      const formula = convertCellValueToStaticFormula(cellValue);
      return formula.slice(1); // Remove leading "="
    });
    return `=GROUP(${nestedFormulas.join(", ")})`;
  }

  // Since we cannot use a switch statement here, we check that the type of
  // value is never. This ensures that when the CellValue type changes, compiler
  // errors are thrown.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exhaustiveCheck: never = value;
  throw new Error("Exhaustive check failed");
};

/**
 * Converts a HyperFormula cell value into a valid parameter value usable by the
 * Task Execution Service.
 *
 * If the cell value is a `Group`, it will be converted into multiple parameter
 * values.
 * @param value
 * @returns An array of analysis parameter values.
 */
export const convertCellValueToAnalysisParamValues = (
  value: CellValue,
): AnalysisParamValue[] => {
  // Ignore values that cannot be converted
  if (
    value === null ||
    value instanceof DetailedCellError ||
    value instanceof RowIdentifier
  ) {
    return [];
  }

  // Ignore empty strings
  if (typeof value === "string") {
    return value !== "" ? [value] : [];
  }

  // Scalars do not require conversion
  if (typeof value === "number" || typeof value === "boolean") {
    return [value];
  }

  // Files are converted to file IDs
  if (value instanceof IdeasFile) {
    return [value.attrs.id];
  }

  // ROI frames are converted to JSON serialized shape data
  if (value instanceof RoiFrame) {
    return [value.shapes];
  }

  // Cell statuses are converted to an array of numbers
  if (value instanceof CellStatuses) {
    return [value.statuses];
  }

  // Groups are converted to an array of values
  if (value instanceof Group) {
    return value.cellValues.flatMap(convertCellValueToAnalysisParamValues);
  }

  // Since we cannot use a switch statement here, we check that the type of
  // value is never. This ensures that when the CellValue type changes, compiler
  // errors are thrown.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const exhaustiveCheck: never = value;
  throw new Error("Exhaustive check failed");
};
