/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  CellStatuses,
  CellValue,
  DetailedCellError,
  Group,
  IdeasFile,
  RoiFrame,
  RowIdentifier,
} from "@inscopix/ideas-hyperformula";
import { CellErrorLoading } from "../store/engine";
import { CellValueRendererLoading } from "./CellValueRendererLoading";
import { CellValueRendererError } from "./CellValueRendererError";
import { CellValueRendererFile } from "./CellValueRendererFile";
import { CellValueRendererRoiFrame } from "./CellValueRendererRoiFrame";
import { CellValueRendererRowIdentifier } from "./CellValueRendererRowIdentifier";
import {
  EuiFlexGroup,
  EuiFlexItem,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { CellValueRendererCellStatuses } from "./CellValueRendererCellStatuses";

const styles = {
  default: css`
    overflow: hidden;
    text-overflow: ellipsis;
  `,
};

interface CellValueRendererBaseProps {
  value: CellValue;
  isInGroup?: boolean;
}

/** Component that renders any cell value */
export const CellValueRendererBase = ({
  value,
  isInGroup = false,
}: CellValueRendererBaseProps) => {
  if (value instanceof DetailedCellError) {
    if (value.message === CellErrorLoading.message) {
      return <CellValueRendererLoading />;
    } else {
      return <CellValueRendererError error={value} />;
    }
  }

  if (value instanceof IdeasFile) {
    return <CellValueRendererFile file={value} isCompact={isInGroup} />;
  }

  if (value instanceof RoiFrame) {
    return <CellValueRendererRoiFrame roiFrame={value} />;
  }

  if (value instanceof RowIdentifier) {
    return <CellValueRendererRowIdentifier rowIdentifier={value} />;
  }

  if (value instanceof CellStatuses) {
    return <CellValueRendererCellStatuses cellStatuses={value} />;
  }

  if (value instanceof Group) {
    if (value.cellValues.length === 0) {
      return null;
    } else if (value.cellValues.length === 1) {
      return <CellValueRendererBase value={value.cellValues[0]} />;
    } else {
      return (
        <EuiFlexGroup alignItems="center" gutterSize="xs">
          {value.cellValues.map((cellValue) => (
            <EuiFlexItem grow={false} key={htmlIdGenerator()()}>
              <CellValueRendererBase value={cellValue} isInGroup />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      );
    }
  }

  return <span css={styles.default}>{value?.toString()}</span>;
};
