import {
  EuiButtonIcon,
  EuiLink,
  EuiPopover,
  EuiText,
} from "@inscopix/ideas-eui";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { captureException } from "@sentry/react";

interface ColumnHeaderBaseJoinLinkProps {
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "join" }>;
}

export const ColumnHeaderBaseJoinLink = ({
  columnDefinition,
}: ColumnHeaderBaseJoinLinkProps) => {
  const gridApi = useDataTableContext((s) => s.gridApi);
  const leftColumn = useDataTableContext(
    (s) => {
      const leftColumnId = columnDefinition.left_column.id;
      const table = s.tables.find(({ id }) => id === s.selectedTableId);
      return table?.columns.find(({ id }) => id === leftColumnId);
    },
    // Only recalculate if name is changed or column is deleted
    (a, b) => a?.name === b?.name,
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // Display nothing if left column was deleted
  if (leftColumn === undefined) {
    return null;
  }

  const jumpToLeftColumn = () => {
    try {
      gridApi?.ensureColumnVisible(leftColumn.id, "start");
      // Wait a tick for the scroll to complete
      setTimeout(() => {
        gridApi?.flashCells({ columns: [leftColumn.id] });
      }, 0);
    } catch (error) {
      // TODO: investigate the cause of this error
      // https://github.com/ag-grid/ag-grid/issues/5085
      captureException(error);
    } finally {
      setIsPopoverOpen(false);
    }
  };

  return (
    <EuiPopover
      button={
        <EuiButtonIcon
          aria-label="Join column source"
          color="primary"
          size="xs"
          iconSize="s"
          iconType="link"
          onClick={() => setIsPopoverOpen((isOpen) => !isOpen)}
        />
      }
      isOpen={isPopoverOpen}
      closePopover={() => setIsPopoverOpen(false)}
      anchorPosition="downCenter"
      ownFocus={false}
    >
      <div style={{ width: "300px" }}>
        <EuiText size="s">
          <p>
            Cell values in this column cannot be manually edited. Their values
            are derived from the values set in the{" "}
            <EuiLink onClick={jumpToLeftColumn}>{leftColumn.name}</EuiLink>{" "}
            column.
          </p>
        </EuiText>
      </div>
    </EuiPopover>
  );
};
