/** @jsxImportSource @emotion/react */
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  htmlIdGenerator,
} from "@inscopix/ideas-eui";
import { useRef, useState } from "react";
import { css } from "@emotion/react";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import assert from "assert";
import { FieldTable } from "../fields/FieldTable";
import { FieldColumnName } from "../fields/FieldColumnName";
import { FieldColumnMulti } from "../fields/FieldColumnMulti";

type Fields = {
  tableId: {
    value: string | undefined;
    isValid: boolean;
  };
  headerName: {
    value: string;
    isValid: boolean;
    isDirty: boolean;
  };
  columnIds: {
    value: string[];
    isValid: boolean;
  };
};

const INITIAL_FIELDS: Fields = {
  tableId: {
    value: undefined,
    isValid: false,
  },
  headerName: {
    value: "",
    isValid: false,
    isDirty: false,
  },
  columnIds: {
    value: [],
    isValid: false,
  },
};

interface ModalJoinDataTableProps {
  onBack: () => void;
  onClose: () => void;
}

/** Component that renders a modal for joining data tables */
export const ModalJoinDataTable = ({
  onBack,
  onClose,
}: ModalJoinDataTableProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const joinDataTable = useDataTableContext((s) => s.joinDataTable);
  const headerNameFieldRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formId] = useState(htmlIdGenerator()());
  const [fields, setFields] = useState(INITIAL_FIELDS);

  /**
   * Shifts focus to the name field.
   *
   * This is tried multiple times because EuiSuperSelect (used by
   * FieldFileMetadatum) refocuses itself after onChange is called. I believe
   * this is a bug in EUI but I think this is the best option in the absence of
   * a long-term fix.
   */
  const focusNameField = () => {
    const timer = setInterval(() => {
      if (document.activeElement !== headerNameFieldRef.current) {
        headerNameFieldRef.current?.focus();
      } else {
        clearInterval(timer);
      }
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      assert(selectedTableId !== undefined);
      const sourceTableId = fields.tableId.value;
      assert(sourceTableId !== undefined);
      const headerName = fields.headerName.value;
      assert(headerName !== undefined);
      const { error } = await joinDataTable({
        tableId: selectedTableId,
        headerName,
        sourceTableId,
        sourceColumnIds: fields.columnIds.value,
      });
      assert(error === undefined);
      addUtilityToastSuccess("Data table joined");
    } catch (error) {
      addUtilityToastFailure("Failed to join data table");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <EuiModal
      onClose={onClose}
      css={css`
        width: 600px;
      `}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">
          Join Data Table
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiForm
        id={formId}
        component="form"
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          void handleSubmit();
        }}
      >
        <EuiModalBody>
          <FieldTable
            filter={{
              tableKind: "data",
              includeSelectedTable: false,
            }}
            value={fields.tableId.value}
            onChange={(value) => {
              // Update state
              setFields((prevFields) => {
                const newFields = { ...prevFields };

                // Set new table ID
                newFields.tableId = {
                  value: value?.id,
                  isValid: value?.id !== undefined,
                };

                // Reset columns field
                newFields.columnIds = {
                  value: [],
                  isValid: false,
                };

                // Auto-fill name field if not previously edited
                if (value !== undefined && !fields.headerName.isDirty) {
                  newFields.headerName = {
                    value: value.name,
                    isValid: value.name !== "",
                    isDirty: false,
                  };
                }

                return newFields;
              });

              // Shift focus to name field
              focusNameField();
            }}
          />

          <FieldColumnName
            ref={headerNameFieldRef}
            label="Header name"
            placeholder="Untitled header"
            value={fields.headerName.value}
            onChange={(field) => {
              setFields((prevFields) => {
                const newFields = { ...prevFields };
                newFields.headerName = {
                  value: field.value,
                  isValid: field.isValid,
                  isDirty: true,
                };
                return newFields;
              });
            }}
          />

          {fields.tableId.value !== undefined && (
            <FieldColumnMulti
              tableId={fields.tableId.value}
              value={fields.columnIds.value}
              onChange={(columnIds) => {
                setFields((prevFields) => {
                  const newFields = { ...prevFields };
                  newFields.columnIds = {
                    value: columnIds,
                    isValid: columnIds.length > 0,
                  };
                  return newFields;
                });
              }}
            />
          )}
        </EuiModalBody>
      </EuiForm>

      <EuiModalFooter>
        <EuiButtonEmpty iconType="arrowLeft" onClick={onBack}>
          Back
        </EuiButtonEmpty>
        <EuiButton
          type="submit"
          form={formId}
          isLoading={isLoading}
          fill
          disabled={Object.values(fields).some((f) => !f.isValid)}
        >
          Insert
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
