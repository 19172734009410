/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { memo, useCallback, useEffect, useState } from "react";
import { DataTableRowData } from "../layout/DataTable";
import { CellEditorBoolean } from "./CellEditorBoolean";
import { useDataTableContext } from "../store/DataTableProvider";
import { CellEditorFormula } from "./CellEditorFormula";
import { CellEditorIdentifier } from "./CellEditorIdentifier";
import { CellEditorFloat } from "./CellEditorFloat";
import { CellEditorText } from "./CellEditorText";
import { CellEditorInteger } from "./CellEditorInteger";
import { CellEditorChoice } from "./CellEditorChoice";
import { CellEditorRoiFrame } from "./CellEditorRoiFrame";
import { CustomCellEditorProps } from "ag-grid-react";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { CellValue } from "@inscopix/ideas-hyperformula";
import { CellEditorFile } from "./CellEditorFile";
import { CellEditorCellStatus } from "./CellEditorCellStatus";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding: 0px 11px;
    outline: 1px solid #07c;
    outline-offset: -1px;
  `,
};

type CellEditorGenericProps = {
  tableId: string;
  tableKind: "data" | "analysis";
  columnId: string;
  columnName: string;
  columnDefinition: DataTableColumnDefinition;
  rowId: string;
  formula: string;
  value: CellValue;
} & Pick<
  CustomCellEditorProps<DataTableRowData>,
  "api" | "onValueChange" | "stopEditing"
>;

/** Base cell editor that wraps all column-type specific cell editors */
const CellEditorBase = ({
  api,
  tableId,
  tableKind,
  columnId,
  columnName,
  columnDefinition,
  rowId,
  stopEditing: stopEditingNative,
  onValueChange,
  formula,
  value,
}: CellEditorGenericProps) => {
  const isAdvancedMode = useDataTableContext((s) => s.isAdvancedMode);
  const [newFormula, setNewFormula] = useState(formula);

  // Notify the grid of the new formula. If the formula has not changed, set the
  // new value to undefined to prevent a cell edit request event from firing.
  useEffect(() => {
    if (newFormula !== formula) {
      onValueChange(newFormula);
    } else {
      onValueChange(undefined);
    }
  }, [formula, newFormula, onValueChange]);

  /**
   * Stops the editing.
   *
   * The native `stopEditing` function is not used directly because, even when
   * the cancel flag is set, the grid still fires a cell edit request event.
   * @param cancel Pass `true` if you want to cancel the editing (i.e. don't
   * accept changes).
   */
  const stopEditing = useCallback(
    (cancel?: boolean) => {
      if (cancel) {
        onValueChange(undefined);
      }
      stopEditingNative(cancel);
    },
    [onValueChange, stopEditingNative],
  );

  const editor = (() => {
    if (isAdvancedMode) {
      return (
        <CellEditorFormula
          formula={newFormula}
          onChangeFormula={setNewFormula}
          stopEditing={stopEditing}
        />
      );
    }

    switch (columnDefinition.kind) {
      case "boolean":
        return (
          <CellEditorBoolean
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "cell_status":
        return (
          <CellEditorCellStatus
            gridApi={api}
            rowId={rowId}
            value={value}
            columnDefinition={columnDefinition}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "choice":
        return (
          <CellEditorChoice
            columnDefinition={columnDefinition}
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "file":
        return (
          <CellEditorFile
            tableId={tableId}
            tableKind={tableKind}
            columnId={columnId}
            rowId={rowId}
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "float":
        return (
          <CellEditorFloat
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "identifier":
        return (
          <CellEditorIdentifier
            columnDefinition={columnDefinition}
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "integer":
        return (
          <CellEditorInteger
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );

      case "roi_frame":
        return (
          <CellEditorRoiFrame
            gridApi={api}
            rowId={rowId}
            value={value}
            columnName={columnName}
            columnDefinition={columnDefinition}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      case "text":
        return (
          <CellEditorText
            value={value}
            onChangeFormula={setNewFormula}
            stopEditing={stopEditing}
          />
        );
      // These columns types are not manually editable
      case "join":
      case "metadatum":
        return null;
    }
  })();

  return <span css={styles.root}>{editor}</span>;
};

export const CellEditorBaseMemo = memo(CellEditorBase);
