/** @jsxImportSource @emotion/react */
import { memo, useState } from "react";
import { EuiButtonEmpty, EuiButtonIcon, EuiPopover } from "@inscopix/ideas-eui";
import { css } from "@emotion/react";
import { ColumnGroupHeaderBaseName } from "./ColumnGroupHeaderBaseName";
import { ColumnGroupHeaderActionRemoveGroup } from "./actions/ColumnGroupHeaderActionRemoveGroup";
import { ModelDeleteColumnGroup } from "../modals/ModelDeleteColumnGroup";

const styles = {
  root: css`
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  `,
  contextMenu: css`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,
};

interface ColumnGroupHeaderBaseProps {
  displayName: string;
  tableId: string;
  tableKind: "data" | "analysis";
  columnGroupId: string;
}

/**
 * Component that renders a header for all column group types.
 *
 * All all other header components should render this header and specify props
 * for displaying custom content.
 */
const ColumnGroupHeaderBase = ({
  displayName,
  columnGroupId,
  tableId,
  tableKind,
}: ColumnGroupHeaderBaseProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const togglePopover = () => setIsPopoverOpen((isOpen) => !isOpen);
  const closePopover = () => setIsPopoverOpen(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [visibleModal, setVisibleModal] = useState<"remove">();

  return (
    <div
      css={styles.root}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      {visibleModal === "remove" && (
        <ModelDeleteColumnGroup
          groupId={columnGroupId}
          onClose={() => setVisibleModal(undefined)}
        />
      )}

      <ColumnGroupHeaderBaseName
        tableId={tableId}
        columnGroupId={columnGroupId}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        name={displayName}
      />

      {(isMouseOver || isPopoverOpen) && (
        <EuiPopover
          button={
            <EuiButtonIcon
              aria-label="Context menu"
              color="text"
              iconType={() => (
                <span className="ag-icon ag-icon-menu" unselectable="on" />
              )}
              size="xs"
              onClick={togglePopover}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          anchorPosition="downLeft"
          panelPaddingSize="none"
          ownFocus={false}
        >
          <div css={styles.contextMenu}>
            <EuiButtonEmpty
              onClick={closePopover}
              iconType="iInCircle"
              color="text"
              size="s"
              disabled
            >
              Details
            </EuiButtonEmpty>

            <EuiButtonEmpty
              onClick={() => {
                closePopover();
                setIsRenaming(true);
              }}
              iconType="pencil"
              color="text"
              size="s"
            >
              Rename column group
            </EuiButtonEmpty>

            {tableKind === "data" && (
              <ColumnGroupHeaderActionRemoveGroup
                onClick={() => {
                  setVisibleModal("remove");
                  closePopover();
                }}
                isDisabled={false}
              />
            )}
          </div>
        </EuiPopover>
      )}
    </div>
  );
};

export const ColumnGroupHeaderBaseMemo = memo(ColumnGroupHeaderBase);
