import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from "@inscopix/ideas-eui";
import {
  CellStatuses,
  CellValue,
  Group,
  IdeasFile,
} from "@inscopix/ideas-hyperformula";
import { ModalToolCellStatusParamBase } from "components/ToolParamsGrid/ModalToolCellStatusParam/ModalToolCellStatusParam";
import { ModalToolCellStatusParamWrapper } from "components/ToolParamsGrid/ModalToolCellStatusParam/ModalToolCellStatusParamWrapper";
import { sleep } from "utils/sleep";
import { DataTableColumnDefinition } from "../store/DataTableProvider.types";
import { GridApi } from "ag-grid-community";
import { captureException } from "@sentry/react";
import assert from "assert";
import { isNonNullish } from "utils/isNonNullish";
import { useDataTableContext } from "../store/DataTableProvider";
import { CellStatus } from "components/CellStatusEditor/CellStatusEditor.types";
import { CallOutError } from "components/CallOutError/CallOutError";

/**
 * Extract file IDs from a cell value.
 * @param value
 * @returns The file IDs.
 */
const extractFileIdsFromCellValue = (value: CellValue) => {
  if (value instanceof IdeasFile) {
    return [value.attrs.id];
  } else if (value instanceof Group) {
    return value.cellValues
      .filter((value) => value instanceof IdeasFile)
      .map((file) => (file as IdeasFile).attrs.id);
  } else {
    return [];
  }
};

interface CellEditorCellStatusProps {
  gridApi: GridApi;
  value: CellValue;
  rowId: string;
  columnDefinition: Extract<DataTableColumnDefinition, { kind: "cell_status" }>;
  onChangeFormula: (newFormula: string) => void;
  stopEditing: (cancel?: boolean) => void;
}

/** Cell editor for cells in text columns */
export const CellEditorCellStatus = ({
  gridApi,
  value,
  rowId,
  columnDefinition,
  onChangeFormula,
  stopEditing,
}: CellEditorCellStatusProps) => {
  // Parse column containing source cell set, event set and qc report files
  const { cellSetColumn, cellSetFileIds, eventSetFileIds, qcReportFileIds } =
    useDataTableContext((s) => {
      const table = s.tables.find(({ id }) => id === s.selectedTableId);
      const cellSetColumnId = columnDefinition.cell_set_file_column.id;
      const cellSetColumnIdx = table?.columns.findIndex(
        ({ id }) => id === cellSetColumnId,
      );
      const eventSetColumnId = columnDefinition.event_set_file_column.id;
      const eventSetColumnIdx = table?.columns.findIndex(
        ({ id }) => id === eventSetColumnId,
      );
      const qcReportColumnId = columnDefinition.qc_report_file_column.id;
      const qcReportColumnIdx = table?.columns.findIndex(
        ({ id }) => id === qcReportColumnId,
      );
      const row = table?.rows.find(({ id }) => id === rowId);

      if (
        table === undefined ||
        cellSetColumnIdx === undefined ||
        row === undefined
      ) {
        return {};
      }

      return {
        cellSetColumn: table.columns[cellSetColumnIdx],
        cellSetFileIds: extractFileIdsFromCellValue(
          row.cells[cellSetColumnIdx].value,
        ),
        eventSetFileIds:
          eventSetColumnIdx !== undefined
            ? extractFileIdsFromCellValue(row.cells[eventSetColumnIdx].value)
            : [],
        qcReportFileIds:
          qcReportColumnIdx !== undefined
            ? extractFileIdsFromCellValue(row.cells[qcReportColumnIdx].value)
            : [],
      };
    });

  const onAccept = (statuses: CellStatus[]) => {
    onChangeFormula(`=CELL_STATUSES(${statuses.join(",")})`);
    setTimeout(stopEditing, 100);
  };

  if (cellSetColumn === undefined) {
    return (
      <ModalToolCellStatusParamWrapper onClose={() => stopEditing()}>
        <CallOutError />
      </ModalToolCellStatusParamWrapper>
    );
  }

  return (
    <ModalToolCellStatusParamBase
      cellSetFileIds={cellSetFileIds}
      eventSetFileIds={eventSetFileIds}
      qcReportFIleIds={qcReportFileIds}
      onClose={stopEditing}
      onSave={onAccept}
      isReadOnly={false}
      isInvalidInitialCellStatuses={!(value instanceof CellStatuses)}
      initialCellStatuses={
        value instanceof CellStatuses ? value.statuses : undefined
      }
      NoSourceFiles={() => (
        <ModalToolCellStatusParamWrapper
          onClose={() => stopEditing()}
          maxWidth="600px"
          readOnly={false}
        >
          <EuiCallOut>
            <EuiText>
              <p>
                To view/edit cell statuses you must first select a cell set
                input file of the appropriate type in the{" "}
                <EuiLink
                  onClick={() =>
                    void (async () => {
                      try {
                        stopEditing();
                        gridApi?.ensureColumnVisible(cellSetColumn.id, "start");
                        // Wait a tick for the scroll to complete
                        await sleep(0);
                        const rowNode = gridApi?.getRowNode(rowId);
                        assert(isNonNullish(rowNode));
                        gridApi?.flashCells({
                          rowNodes: [rowNode],
                          columns: [cellSetColumn.id],
                        });
                      } catch (err) {
                        // TODO: investigate the cause of this error
                        // https://github.com/ag-grid/ag-grid/issues/5085
                        captureException(err);
                      }
                    })()
                  }
                >
                  {cellSetColumn.name}
                </EuiLink>{" "}
                column.
              </p>
            </EuiText>
          </EuiCallOut>
          <EuiSpacer />
        </ModalToolCellStatusParamWrapper>
      )}
    />
  );
};
