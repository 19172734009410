import { EuiSuperSelect, EuiSuperSelectOption } from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";

interface FieldDefaultFormulaBooleanProps {
  autoFocus?: boolean;
  value: string | undefined;
  onChange: (field: { value: string | undefined; isValid: boolean }) => void;
}

export const FieldDefaultFormulaBoolean = ({
  autoFocus,
  value,
  onChange,
}: FieldDefaultFormulaBooleanProps) => {
  const options: EuiSuperSelectOption<string>[] = [
    { value: "=TRUE()", inputDisplay: "True" },
    { value: "=FALSE()", inputDisplay: "False" },
  ];

  const handleChange = (newValue: string) => {
    if (newValue === value?.toString()) {
      onChange({
        value: undefined,
        isValid: true,
      });
    } else {
      onChange({
        value: newValue,
        isValid: true,
      });
    }
  };

  return (
    <FieldDefaultFormula>
      <EuiSuperSelect
        fullWidth
        options={options}
        valueOfSelected={value?.toString()}
        onChange={handleChange}
        placeholder="None"
        autoFocus={autoFocus}
      />
    </FieldDefaultFormula>
  );
};
