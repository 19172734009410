import { EuiFieldNumber } from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";

interface FieldDefaultFormulaFloatProps {
  autoFocus?: boolean;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const FieldDefaultFormulaFloat = ({
  autoFocus,
  value,
  onChange,
}: FieldDefaultFormulaFloatProps) => {
  return (
    <FieldDefaultFormula>
      <EuiFieldNumber
        fullWidth
        autoFocus={autoFocus}
        placeholder="None"
        value={value}
        // onChange is only fired when the value is a valid number. onInput is
        // fired every time the value changes.
        onInput={(e) => onChange(e.currentTarget.value)}
      />
    </FieldDefaultFormula>
  );
};
