import { useMemo } from "react";
import { ModalDandi } from "./ModalDandi";
import { EuiTableComputedColumnType } from "@inscopix/ideas-eui";
import { filesize } from "utils/filesize";
import { chain } from "lodash";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { IdeasFile, RowIdentifier } from "@inscopix/ideas-hyperformula";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";
import { CellValueRendererFile } from "pages/gdt/cell-value-renderers/CellValueRendererFile";
import { CellValueRendererRowIdentifier } from "pages/gdt/cell-value-renderers/CellValueRendererRowIdentifier";

/**
 * Determines whether a file belongs to specified data column.
 * @param file
 * @param columnId
 * @returns `true` if the file belongs to the data column, `false` otherwise.
 */
const isFileInColumn = (file: { columnId: string }, columnId: string) => {
  return file.columnId === columnId;
};

// Columns rendered in the file selection table
const fileTableColumns: EuiTableComputedColumnType<{
  id: string;
  referenceId: string;
  name: string;
  status: number;
  fileType: number | null;
  isSeries: boolean;
  source: string;
  processingStatus: number;
  seriesParentId: string | null;
  tableKey: string;
  rowIndex: number;
  size: string;
}>[] = [
  {
    name: "ID",
    render: (file) => {
      const rowIdentifier = new RowIdentifier(file.tableKey, file.rowIndex);
      return <CellValueRendererRowIdentifier rowIdentifier={rowIdentifier} />;
    },
  },
  {
    name: "File",
    render: (file) => <CellValueRendererFile file={new IdeasFile(file)} />,
  },
  {
    name: "Size",
    render: (file) => filesize(file.size),
  },
];

export interface ModalDandiGdtProps {
  onClose: () => void;
}

/** Component for exporting files from generic data tables to DANDI */
export const ModalDandiGdt = ({ onClose }: ModalDandiGdtProps) => {
  const projectId = useDataTableContext((s) => s.project.id);
  const gridApi = useDataTableContext((s) => s.gridApi);

  // NWB files in the current table
  const nwbFiles = useDataTableContext((s) => {
    const table = s.tables.find(({ id }) => id === s.selectedTableId);

    if (table === undefined) {
      return [];
    }

    const files: {
      id: string;
      referenceId: string;
      name: string;
      status: number;
      fileType: number | null;
      isSeries: boolean;
      source: string;
      processingStatus: number;
      seriesParentId: string | null;
      tableKey: string;
      rowIndex: number;
      size: string;
      columnId: string;
    }[] = [];

    table.rows.forEach((row) => {
      row.cells.forEach((cell, colIdx) => {
        const column = table.columns[colIdx];
        if (
          cell.value instanceof IdeasFile &&
          cell.value.attrs.fileType === FILE_TYPES_BY_KEY["nwb_data"].id
        ) {
          files.push({
            ...cell.value.attrs,
            tableKey: table.key,
            rowIndex: row.index,
            columnId: column.id,
            size: "0",
          });
        }
      });
    });

    return files;
  });

  // Columns containing NWB files
  const dataColumnIds = useMemo(() => {
    return chain(nwbFiles)
      .map((file) => file.columnId)
      .uniq()
      .value();
  }, [nwbFiles]);

  return (
    <ModalDandi
      gridApi={gridApi}
      onClose={onClose}
      files={nwbFiles}
      isFileInColumn={isFileInColumn}
      projectId={projectId}
      fileTableColumns={fileTableColumns}
      dataColumnIds={dataColumnIds}
    />
  );
};
