/** @jsxImportSource @emotion/react */
import {
  EuiText,
  EuiCallOut,
  EuiTreeView,
  EuiLoadingLogo,
  EuiEmptyPrompt,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ComponentType, useState } from "react";
import { css } from "@emotion/react";
import { CellStatusEditorProps } from "components/CellStatusEditor/CellStatusEditor";
import {
  CellStatus,
  CellStatuses,
} from "components/CellStatusEditor/CellStatusEditor.types";
import { ModalToolCellStatusParamWrapper } from "./ModalToolCellStatusParamWrapper";
import { ModalToolCellStatusParamWithFile } from "./ModalToolCellStatusParamWithFile";
import { useModalToolCellStatusParamData } from "./useModalToolCellStatusParamData";
import { FILE_TYPES_BY_KEY } from "types/FileTypes";

export type SelectedSourceFile = Pick<
  ReturnType<typeof useModalToolCellStatusParamData>["files"][number],
  "id" | "metadata"
>;

export type ModalToolCellStatusParamBaseProps = {
  cellSetFileIds: string[];
  eventSetFileIds: string[];
  qcReportFIleIds: string[];
  initialCellStatuses?: CellStatuses;
  isReadOnly: boolean;
  onSave: (statuses: CellStatus[]) => void;
  onClose: () => void;
  // Grids using this modal will allow users to jump to the file input column
  // when no files are found. This requires access to a grid API instance and
  // should be decoupled from the base modal implementation.
  NoSourceFiles: ComponentType;
} & Pick<CellStatusEditorProps, "isInvalidInitialCellStatuses">;

export const ModalToolCellStatusParamBase = ({
  cellSetFileIds,
  eventSetFileIds,
  qcReportFIleIds,
  initialCellStatuses,
  isReadOnly,
  onSave,
  onClose,
  NoSourceFiles,
  isInvalidInitialCellStatuses,
}: ModalToolCellStatusParamBaseProps) => {
  const [selectedSourceFile, setSelectedSourceFile] =
    useState<SelectedSourceFile>();

  const { files, fileIdsNotFound, isLoading, error } =
    useModalToolCellStatusParamData([
      ...cellSetFileIds,
      ...eventSetFileIds,
      ...qcReportFIleIds,
    ]);

  const cellSetFiles = files.filter((file) => {
    const isFromSourceColumn = cellSetFileIds.includes(file.id);
    const isCellSet = file.fileType === FILE_TYPES_BY_KEY["cell_set"].id;
    return isFromSourceColumn && isCellSet;
  });

  const eventSetFiles = files.filter((file) => {
    const isFromSourceColumn = eventSetFileIds.includes(file.id);
    const isEventSet = file.fileType === FILE_TYPES_BY_KEY["neural_events"].id;
    return isFromSourceColumn && isEventSet;
  });

  const qcReportFiles = files.filter((file) => {
    const isFromSourceColumn = qcReportFIleIds.includes(file.id);
    const isQcReport =
      file.fileType === FILE_TYPES_BY_KEY["calcium_image_qc_report"].id;
    return isFromSourceColumn && isQcReport;
  });

  if (isLoading) {
    return (
      <ModalToolCellStatusParamWrapper
        onClose={onClose}
        readOnly={isReadOnly}
        fullScreen
      >
        <EuiEmptyPrompt
          icon={<EuiLoadingLogo logo="vector" size="xl" />}
          title={<h3>Loading...</h3>}
          titleSize="s"
          css={css`
            height: 100%;
            .euiEmptyPrompt__main {
              height: 100%;
            }
          `}
        />
      </ModalToolCellStatusParamWrapper>
    );
  }

  // Failed to fetch file data
  if (error !== undefined) {
    return (
      <ModalToolCellStatusParamWrapper
        onClose={onClose}
        readOnly={isReadOnly}
        fullScreen
      >
        <CallOutError />
      </ModalToolCellStatusParamWrapper>
    );
  }

  /**c
   * None of the selected files could be found (i.e. all have been deleted after they were selected)
   */
  const isFileNotFound = files.length === 0 && fileIdsNotFound.length > 0;

  if (isFileNotFound) {
    return (
      <ModalToolCellStatusParamWrapper onClose={onClose}>
        <EuiCallOut>
          <EuiText>
            <p>File not found.</p>
            <p>
              The selected cell set file could not be located. It may have been
              deleted.{" "}
            </p>
          </EuiText>
        </EuiCallOut>
      </ModalToolCellStatusParamWrapper>
    );
  }

  /**
   * No file selected
   */

  if (cellSetFiles.length === 0) {
    return <NoSourceFiles />;
  }

  /**
   * Auto pick if only one file
   */
  if (
    cellSetFiles.length === 1 &&
    !cellSetFiles[0].isSeries &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(cellSetFiles[0]);
  }

  /**
   * Auto pick if only one series file
   * we just need cell names and statuses, should be the same for all series files
   * so we can auto-pick the first one
   */
  if (
    cellSetFiles.length === 1 &&
    cellSetFiles[0].isSeries &&
    cellSetFiles[0].seriesMembers.length > 0 &&
    selectedSourceFile === undefined
  ) {
    setSelectedSourceFile(cellSetFiles[0].seriesMembers[0]);
  }

  /**
   * When selecting from a file list - auto-pick the first child if it's a series
   */
  const onSelectFile = (drsFile: (typeof files)[number]) => {
    if (drsFile.isSeries) {
      setSelectedSourceFile(drsFile.seriesMembers[0]);
    } else {
      setSelectedSourceFile(drsFile);
    }
  };

  /**
   * Multiple valid files, allow user to select one
   */
  if (selectedSourceFile === undefined) {
    return (
      <ModalToolCellStatusParamWrapper onClose={onClose}>
        <>
          <EuiText>
            Select which input file you would like to use in the cell set
            editor.
          </EuiText>
          <br />
          <EuiTreeView
            style={{
              border: "1px solid lightgrey",
              borderRadius: 5,
              padding: 10,
            }}
            css={css`
              .euiTreeView__nodeLabel {
                width: 100%;
              }
            `}
            aria-label="File tree"
            showExpansionArrows
            expandByDefault
            items={cellSetFiles.map((file) => ({
              id: file.id,
              label: (
                <EuiText onClick={() => onSelectFile(file)} size="s">
                  {file.name}
                </EuiText>
              ),
            }))}
          />
        </>
      </ModalToolCellStatusParamWrapper>
    );
  }

  return (
    <ModalToolCellStatusParamWrapper
      onClose={onClose}
      readOnly={isReadOnly}
      fullScreen
    >
      <ModalToolCellStatusParamWithFile
        onCancel={onClose}
        cellSetFile={selectedSourceFile}
        qcReportFile={qcReportFiles?.[0]}
        eventSetFile={eventSetFiles?.[0]}
        onAcceptStatuses={onSave}
        readOnly={isReadOnly}
        initialCellStatuses={initialCellStatuses}
        isInvalidInitialCellStatuses={isInvalidInitialCellStatuses}
      />
    </ModalToolCellStatusParamWrapper>
  );
};
