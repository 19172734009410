import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@inscopix/ideas-eui";
import { useState } from "react";
import { useDataTableContext } from "../store/DataTableProvider";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { isDefined } from "utils/isDefined";
import assert from "assert";

interface ModelDeleteColumnGroupProps {
  groupId: string;
  onClose: () => void;
}

export const ModelDeleteColumnGroup = ({
  groupId,
  onClose,
}: ModelDeleteColumnGroupProps) => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);
  const deleteDataTableColumnGroup = useDataTableContext(
    (s) => s.deleteDataTableColumnGroup,
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    assert(isDefined(selectedTableId));
    const { error } = await deleteDataTableColumnGroup({
      tableId: selectedTableId,
      groupId,
    });
    setIsLoading(false);

    if (error === undefined) {
      addUtilityToastSuccess("Column group deleted");
    } else {
      addUtilityToastFailure("Failed to delete column group");
    }

    onClose();
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle component="h3">
          Remove Column Group
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiCallOut
          title="Proceed with caution!"
          color="warning"
          iconType="warning"
        >
          <p>
            Cells in the removed column group may be referenced in other tables.
            Removing this column group might caused unwanted changes to your
            data.
          </p>
        </EuiCallOut>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton
          isLoading={isLoading}
          fill
          color="danger"
          onClick={() => void handleSubmit()}
        >
          Remove
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
