import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFormRow,
  EuiTableComputedColumnType,
  EuiToken,
} from "@inscopix/ideas-eui";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";
import { DataTableColumnDefinition } from "pages/gdt/store/DataTableProvider.types";
import { COLUMN_KIND_MAP } from "../ModalCreateColumn/ModalCreateColumn";

const columns: EuiTableComputedColumnType<{
  name: string;
  kind: DataTableColumnDefinition["kind"];
}>[] = [
  {
    name: "Name",
    render: ({ name }) => name,
  },
  {
    name: "Type",
    width: "30%",
    render: ({ kind }) => {
      return (
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiToken iconType={COLUMN_KIND_MAP[kind].icon} />
          <span>{COLUMN_KIND_MAP[kind].name}</span>
        </EuiFlexGroup>
      );
    },
  },
];

export type FieldColumnMultiProps = {
  autoFocus?: boolean;
  tableId: string;
  label?: string;
  value: string[];
  onChange: (columnIds: string[]) => void;
};

export const FieldColumnMulti = ({
  autoFocus = false,
  tableId,
  label = "Columns",
  value,
  onChange,
}: FieldColumnMultiProps) => {
  const items = useDataTableContext((s) => {
    const table = s.tables.find((table) => table.id === tableId);
    return (table?.columns ?? []).map((column) => ({
      id: column.id,
      name: column.name,
      isIdColumn: column.is_id_column,
      kind: column.definition.kind,
    }));
  });

  return (
    <EuiFormRow fullWidth label={label}>
      <EuiBasicTable
        autoFocus={autoFocus}
        columns={columns}
        items={items}
        itemId={(item) => item.id}
        selection={{
          selectable: (item) => !item.isIdColumn,
          selectableMessage: (_, item) =>
            item.isIdColumn
              ? "This column is included by default"
              : "Select this column",
          selected: items.filter((item) => value.includes(item.id)),
          onSelectionChange: (selected) => {
            const columnIds = selected.map(({ id }) => id);
            onChange(columnIds);
          },
        }}
      />
    </EuiFormRow>
  );
};
