import { EuiFieldText } from "@inscopix/ideas-eui";
import { FieldDefaultFormula } from "./FieldDefaultFormula";

interface FieldDefaultFormulaTextProps {
  autoFocus?: boolean;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

export const FieldDefaultFormulaText = ({
  autoFocus,
  value,
  onChange,
}: FieldDefaultFormulaTextProps) => {
  return (
    <FieldDefaultFormula>
      <EuiFieldText
        fullWidth
        autoFocus={autoFocus}
        placeholder="None"
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value)}
        maxLength={255}
      />
    </FieldDefaultFormula>
  );
};
