/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef } from "react";
import { useDataTableContext } from "../store/DataTableProvider";

const styles = {
  root: css`
    background: none;
    border: none;
    cursor: default;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `,
};

interface ColumnGroupHeaderBaseNameProps {
  tableId: string;
  columnGroupId: string;
  isRenaming: boolean;
  setIsRenaming: (isRenaming: boolean) => void;
  name: string;
}

export const ColumnGroupHeaderBaseName = ({
  tableId,
  columnGroupId,
  isRenaming,
  setIsRenaming,
  name,
}: ColumnGroupHeaderBaseNameProps) => {
  const renameColumnGroup = useDataTableContext((s) => s.renameColumnGroup);
  const inputRef = useRef<HTMLInputElement>(null);

  if (!isRenaming) {
    return <span css={styles.root}>{name}</span>;
  }

  return (
    <input
      css={styles.root}
      ref={inputRef}
      autoFocus
      type="text"
      defaultValue={name}
      onChange={(e) => {
        const input = inputRef.current;
        if (input !== null) {
          input.value = e.target.value;
        }
      }}
      onBlur={() => {
        setIsRenaming(false);
        const input = inputRef.current;
        if (input !== null && input.value !== "" && input.value !== name) {
          void renameColumnGroup({
            tableId,
            groupId: columnGroupId,
            newName: input.value,
          });
        }
      }}
      onKeyDownCapture={(e) => {
        e.stopPropagation();
        const input = inputRef.current;
        if (input !== null) {
          if (e.key === "Enter") {
            input.blur();
          } else if (e.key === "Escape") {
            input.value = name;
            input.blur();
          }
        }
      }}
    />
  );
};
